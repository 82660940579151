import * as React from "react"
import { FC, useContext } from "react"
import styled from "styled-components"
import Card from "../../components/Card"
import { UpdatePasswordFormWrapper } from "@social-supermarket/social-supermarket-components"
import { getConfig } from "../../../config"
import { GlobalContext } from "../../context/GlobalProvider"

const Container = styled(Card)`
  width: 100%;
  max-width: 800px;
  margin: auto;
  margin-top: 20px;
  padding: 20px;
`

interface Props {}

const ProfilePage: FC<Props> = () => {
  const { showGrowl } = useContext(GlobalContext)
  const handleSuccess = () => {
    showGrowl("Password updated.")
  }
  return (
    <Container>
      <UpdatePasswordFormWrapper baseUrl={getConfig().baseUrl} onSuccess={handleSuccess} />
    </Container>
  )
}

export default ProfilePage
