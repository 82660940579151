import * as React from "react"
import { useContext } from "react"
import Layout from "../social-supermarket/components/Layout"
import { LoginContext } from "@social-supermarket/social-supermarket-components"
import SEO from "../social-supermarket/components/Seo"
import ProfilePage from "../social-supermarket/pages/profile/ProfilePage"

const Profile = () => {
  const loginContext = useContext(LoginContext)
  return loginContext.user ? (
    <Layout>
      <SEO title={"Your Profile"} description={""} />
      <ProfilePage />
    </Layout>
  ) : (
    <Layout />
  )
}

export default Profile
