import styled from "styled-components"

const Card = styled.div`
  border-radius: 0px;
  background-color: white;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%),
    0px 1px 3px 0px rgb(0 0 0 / 12%);
  padding: 10px;
  margin: 5px;
`

export default Card
